import type {HomeData} from "~/types/main";
import type {
    ClassifiedAdContactDetails,
    ClassifiedAdReportType, ClassifiedInstantSearchData,
    MainClassifiedAdsData, MainSingleClassifiedAdData, SavedClassifiedAd,
    ApiError, ClassifiedAdSearchData,
} from "~/types";

export const useMainClassifiedDataService = () => {
    const nuxtApp = useNuxtApp();
    const route = useRoute();
    const baseUrl = useRuntimeConfig().public.baseApiUrl;
    const fetchMainClassifiedAd = async () => {
        const {data, error} = await useAsyncData(`classified-ad-${route.params.slug}`,
            () => nuxtApp.$getClassifiedAdBySlug(route.params.slug as string)
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value as MainSingleClassifiedAdData;
        }
    }

    const fetchClassifiedAdContactDetails = async () => {
        try {
            const response = await nuxtApp.$getClassifiedAdContactDetailsBySlug(route.params.slug as string)
            return response.data as ClassifiedAdContactDetails;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const fetchMainClassifiedAdsData = async () => {
        const {data, error, refresh} = await useAsyncData(`main-classified-ads`,
            () => useNuxtApp().$getClassifiedAdsDataFromUrl(`${baseUrl}/classified-ads`)
        );

        if (error.value !== null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return computed(() => {
                return {
                    classified_ad_page: {
                        featured_classified_ads: data.value.featured_classified_ads,
                        non_featured_classified_ads: data.value.classified_ads.data,
                        page_number: data.value.classified_ads.meta.current_page,
                    },
                    pagination_meta_data: data.value.classified_ads.meta,
                    pagination_links: data.value.classified_ads.links,
                } as MainClassifiedAdsData
            });

        }
    };

    const fetchMainClassifiedAdsDataFromUrl = async (url: string) => {
        try {
            const response = await useNuxtApp().$getClassifiedAdsDataFromUrl(url);
            return {
                classified_ad_page: {
                    featured_classified_ads: response.featured_classified_ads,
                    non_featured_classified_ads: response.classified_ads.data,
                    page_number: response.classified_ads.meta.current_page,
                },
                pagination_meta_data: response.classified_ads.meta,
                pagination_links: response.classified_ads.links,
            } as MainClassifiedAdsData;
        } catch (error: any) {
            setError(error.value.data as ApiError);
            return null;
        }
    };

    const fetchClassifiedAdReportTypes = async () => {
        const {data, error} = await useAsyncData('classified-ad-report-types',
            () => nuxtApp.$getClassifiedAdReportTypes()
        );

        if (error.value != null) {
            setError(error.value.data as ApiError);
            return null;
        } else {
            return data.value.data as ClassifiedAdReportType[];
        }
    }

    const requestToCreateNewClassifiedAdReport = async (data: object) => {
        try {
            const response = await nuxtApp.$createClassifiedAdReport(data);
            return response.data.message as string;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const requestToSaveAClassifiedAd = async () => {
        try {
            const response = await nuxtApp.$saveClassifiedAdBySlug(route.params.slug as string);
            return response.data as SavedClassifiedAd;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const fetchInstantSearchResults = async (query: string) => {
        try {
            const response = await nuxtApp.$getInstantSearchResultsForClassifiedByQuery(query)
            return response as ClassifiedInstantSearchData;
        } catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    const fetchClassifiedAdsBySearchCriteria = async(query:string)=>{
        try{
            const response = await nuxtApp.$getClassifiedAdsBySearchCriteria(query);
            return response as ClassifiedAdSearchData;
        }
        catch (error: any) {
            setError(error.data as ApiError);
            return null;
        }
    }

    function setError(error: ApiError) {
        useAlertSetter().setErrorAlert(error);
    }

    return {
        fetchMainClassifiedAd,
        fetchClassifiedAdContactDetails,
        fetchClassifiedAdReportTypes,
        requestToCreateNewClassifiedAdReport,
        requestToSaveAClassifiedAd,
        fetchMainClassifiedAdsData,
        fetchMainClassifiedAdsDataFromUrl,

        fetchInstantSearchResults,
        fetchClassifiedAdsBySearchCriteria,
    }
}