import {useMainClassifiedDataService} from "#build/imports";
import type {ClassifiedAdContactDetails, ClassifiedInstantSearchData} from "~/types";

export const useMainClassifiedDataManager = () => {
    const classifiedStore = useClassified();
    const mainClassifiedDataService = useMainClassifiedDataService();

    const getMainClassifiedAd = async () => {
        return await mainClassifiedDataService.fetchMainClassifiedAd();
    }

    const getClassifiedAdDetails = async () => {
        return await mainClassifiedDataService.fetchClassifiedAdContactDetails() as ClassifiedAdContactDetails;
    }

    const getMainClassifiedAds = async () => {
        const results = await mainClassifiedDataService.fetchMainClassifiedAdsData();
        return results?.value;
    }

    const getMainClassifiedAdsFromUrl = async (url: string) => {
        return await mainClassifiedDataService.fetchMainClassifiedAdsDataFromUrl(url);
    }

    const getClassifiedAdReportTypes = async () => {
        const storedClassifiedAdReportTypes = computed(() => {
            return classifiedStore.classified_ad_report_types;
        })
        if (storedClassifiedAdReportTypes.value == null || storedClassifiedAdReportTypes.value.length == 0 || !classifiedStore.isClassifiedAdReportTypesValid()) {
            const classifiedAdReportTypes =  await mainClassifiedDataService.fetchClassifiedAdReportTypes();
            if(classifiedAdReportTypes)
                classifiedStore.setClassifiedAdReportTypes(classifiedAdReportTypes);
        }

        return storedClassifiedAdReportTypes;
    }

    const reportClassifiedAd = async (data:object) => {
        const createdClassifiedAdReport = await mainClassifiedDataService.requestToCreateNewClassifiedAdReport(data);
        if (createdClassifiedAdReport) {
            useAlertSetter().setSuccessAlert("Classified Listing Reported Successfully");
            return createdClassifiedAdReport;
        }
        return null;
    }

    const saveClassifiedAd = async () => {
        const savedClassifiedAd = await mainClassifiedDataService.requestToSaveAClassifiedAd();
        if (savedClassifiedAd) {
            useAlertSetter().setSuccessAlert("Classified Listing Saved Successfully");
            return savedClassifiedAd;
        }
        return null;
    }

    const getClassifiedAdInstantSearchResults = async (query:string) => {
        return await mainClassifiedDataService.fetchInstantSearchResults(query) as ClassifiedInstantSearchData;
    }

    const searchClassifiedAdsByCriteria = async (query:string)=>{
        const searchResults =  await mainClassifiedDataService.fetchClassifiedAdsBySearchCriteria(query);
        if(searchResults){
            return searchResults
        } else{
            return null;
        }
    }

    return {
        getMainClassifiedAd,
        getClassifiedAdDetails,
        getClassifiedAdReportTypes,
        reportClassifiedAd,
        saveClassifiedAd,
        getMainClassifiedAds,
        getMainClassifiedAdsFromUrl,


        getClassifiedAdInstantSearchResults,
        searchClassifiedAdsByCriteria
    }
}